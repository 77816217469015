import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-godaddy-seal',
  standalone: true,
  imports: [],
  templateUrl: './godaddy-seal.component.html',
  styleUrl: './godaddy-seal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GodaddySealComponent {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.addScript();
  }

  private addScript(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://seal.godaddy.com/getSeal?sealID=DUtKav3Iohxc3AiSgK137RVDavrV043ZeqYWIC2NcG2LPerVAxFmNgvIuzuE';
    script.async = true;
    this.renderer.appendChild(
      this.el.nativeElement.querySelector('#siteseal'),
      script
    );
  }
}
