export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const CAPTURE = 'Capture';
export const VALIDATE = 'ValidateAndSave';
export const PRE_AUTHORIZE = 'PreAuthorize';
export const PAYMETECH_ENDPOINT_DOWN = 'PaymentechEndpoint_Down';
export const VALIDATE_DECLINE = 'Validate_Decline';
export const VOID_DECLINE = 'Void_Decline';
export const PRE_AUTHORIZE_DECLINE = 'PreAuthorize_Decline';
export const CAPTURE_DECLINE = 'Capture_Decline';
export const VERIFY_PRE_AUTH_AMOUNT = 'VerifyPreAuthAmount';
export const AMOUNT_MISMATCHED = 'AMOUNT_MISMATCHED';
export const PRE_AUTHORIZE_APPROVE = 'PreAuthorize_Approve';
export const CAPTURE_APPROVE = 'Capture_Approve';
export const INVALID_CARD_NUMBER='INVALID_CARD_NUMBER';
