import {
  ChangeDetectionStrategy,
  Component,
  Input,
  booleanAttribute,
} from '@angular/core';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  @Input({ required: true }) error: string = '';
  @Input({
    transform: booleanAttribute,
    alias: 'showErrorImg',
  })
  showImg: boolean | string = false;
}
