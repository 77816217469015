type All = string | boolean | number | Date | null | undefined;
export interface ApiParams {
  [key: string]: All;
}

export enum ApiResponseType {
  Json = 'json',
  Text = 'text',
  Arraybuffer = 'arraybuffer',
  Blob = 'blob',
}
