import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input({
    required: true,
    alias: 'paid',
  })
  paid: boolean = false;
  @Input({
    required: true,
    alias: 'errorCode',
  })
  errorCode: number = 0;
}
