import { format } from 'date-fns'; // Import from date-fns

import { jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';

// Function to decode JWT and get expiration date
export function getJwtExpirationDate(token: string): Date {
  const expiredDate = new Date();
  expiredDate.setDate(expiredDate.getDate() - 20);

  try {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken.exp === undefined) {
      return expiredDate;
    }
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);
    return expirationDate;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return expiredDate;
  }
}
export function getJwtOrderNumber(token: string): string | null {
  try {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken.OrderNumber === undefined) {
      return null;
    }
    return decodedToken.OrderNumber;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
}
export function getJwtLocation(token: string): string | null {
  try {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken.LocationNumber === undefined) {
      return null;
    }
    return decodedToken.LocationNumber;
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
}

export function getApiDateString(value: any, defaultValue?: any) {
  if (defaultValue === undefined) {
    defaultValue = value;
  }
  if (value instanceof Date) {
    let compareDate = '0001-01-01T00:00:00';
    try {
      compareDate = format(value, 'YYYY-MM-DDTHH:mm:ss');
    } catch (ex) {}
    return compareDate;
  } else {
    return defaultValue;
  }
}
export function encryptString(inputString: string): string {
  let secretKey = environment.secretKey;
  let encryptedString: string = '';
  if (inputString.trim() !== '') {
    for (let i = 0; i < inputString.length; i++) {
      encryptedString += String.fromCharCode(
        inputString.charCodeAt(i) ^ secretKey.charCodeAt(i % secretKey.length)
      );
    }
  }
  return encryptedString;
}
export function jsonDateReplacer(this: any, key: string, value: any) {
  return getApiDateString(this[key], value);
}
// Function to check if base64 date string is older than two days
// export function isDateOlderThanTwoDays(expDate: Date): boolean {
//   // Decode base64 date string
//   try {
//     // Convert decoded string to Date object
//     const date = new Date(expDate);

//     // Get current date and time
//     const currentDate = new Date();

//     // Calculate difference in milliseconds
//     const differenceInMilliseconds = currentDate.getTime() - date.getTime();

//     // Calculate difference in days
//     const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
//     return differenceInDays > 2;
//   } catch (error) {
//     return true;
//   }
// }
export function isDateExpired(expDate: Date): boolean {
  try {
    // Convert the string to a Date object
    const date = new Date(expDate);

    // Check if the date conversion is valid
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }

    // Get current date and time
    const currentDate = new Date();

    // Return true if the input date is older than the current date and time
    return date < currentDate;
  } catch (error) {
    // If an error occurs, log it and return true (assuming date is invalid and should be considered expired)
    console.error('Error:', error);
    return true;
  }
}
