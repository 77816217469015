<div class="d-flex flex-column min-vh-100">
  <!-- Main content -->
  <div class="flex-grow-1">
    <app-toggle-button (themeChange)="changeTheme($event)" />
    @if(loading){
    <app-loading />
    } @else {
    <app-page-header [paid]="order.Paid" [errorCode]="errorCode" />
    <!-- Wrong URL or Toeken has expired -->
    @if(error && errorCode >= 100){
    <div class="container">
      <app-error [error]="error" showErrorImg></app-error>
    </div>
    }
    <!-- All Good here  load main content when it ready-->
    @defer( when !bExpiredLink && !error; prefetch on idle){
    <div class="container">
      <div class="row">
        <!-- Order Details -->
        <div
          [ngClass]="
            !order.Paid ? 'col-md-5 order-md-first' : 'col-md-8 offset-md-2'
          "
        >
          <h4 class="d-flex justify-content-between align-items-center mb-1">
            <div>
              @if(order.Paid){
              <i class="bi bi-check2-circle text-success me-2"></i>
              }
              <span class="text-primary">Order Details</span>
            </div>
            <!-- Paid -->
            @if(order.Paid){
            <div class="d-flex justify-content-center gap-1">
              <img src="/assets/done.svg" alt="cc logo" class="done" />
            </div>
            }
          </h4>
          <app-order-detail-view
            [order]="order"
            [initialLoad]="initialLoad"
            [currentPayment]="currentPayment"
          />

          <!-- Show CC image below the totals -->
          @if(!order.Paid && !error && !processingError && !isAmountMismatched){
          <div class="d-flex justify-content-center gap-1">
            <img src="/assets/cc.svg" alt="cc logo" class="img-mobile" />
          </div>
          }
          <!-- Server Errors -->
          @if(error && errorCode < 100){
          <div class="d-flex justify-content-center gap-1">
            <app-error [error]="error"></app-error>
          </div>
          } @if(isAmountMismatched){
          <app-amount-mismatch-view
            (refreshPage)="refreshPage()"
          ></app-amount-mismatch-view>
          }
          <!-- Client Errors -->
          @if(processingError && errorCode < 100){
          <app-error [error]="processingError"></app-error>
          }
        </div>
        @if(!order.Paid){
        <!-- CC Info -->
        <div class="col-md-7">
          <!-- CC Info -->
          <h4 class="d-flex justify-content-between align-items-center mb-1">
            <span class="text-primary">Credit Card Info</span>
          </h4>
          <form [formGroup]="ccInfoForm" (submit)="payNow()">
            <div class="card p-2 bg-light mb-3">
              <div class="container mt-3">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="cardholderName" class="form-label"
                      >*Card Holder Name</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="cardholderName"
                      placeholder="Enter Cardholder Name"
                      formControlName="CardHolderName"
                      tabindex="0"
                      maxlength="80"
                      spellcheck="false"
                    />
                    @if(cardholderNameControl.touched &&
                    cardholderNameControl.errors?.['required']){

                    <small class="text-danger">
                      Card Holder Name is required.
                    </small>
                    }
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="paymentReceiptEmail" class="form-label"
                      >*Payment Receipt Email Address</label
                    >
                    <div class="input-group">
                      <span class="input-group-text"
                        ><i class="bi bi-at"></i
                      ></span>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="paymentReceiptEmail"
                        placeholder="Enter Email Address"
                        formControlName="EmailAddress"
                        (input)="validateEmail()"
                        maxlength="80"
                        tabindex="1"
                      />
                    </div>
                    @if(emailControl.touched &&
                    emailControl.errors?.['required']){
                    <small class="text-danger"
                      >Email Address is required.
                    </small>
                    } @if(!isEmailValid){
                    <small class="text-danger"
                      >Email Address is not valid.
                    </small>
                    }
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="cardType" class="form-label"
                      >*Credit Card Type</label
                    >
                    <select
                      id="cardType"
                      class="form-select form-select-sm"
                      formControlName="CreditCardTypeId"
                      tabindex="3"
                    >
                      <option value="">Select Card Type</option>
                      <option value="1">Visa</option>
                      <option value="2">Mastercard</option>
                      <option value="3">American Express</option>
                      <option value="4">Discover</option>
                    </select>
                    @if(creditCardTypeControl.errors &&
                    creditCardTypeControl.touched){
                    <small class="text-danger">
                      Card Card Type is invalid.
                    </small>
                    }
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="d-flex justify-content-between">
                      <label for="cardNumber" class="form-label"
                        >*Card Number</label
                      >
                      <small class="mt-2">{{ ccLength }}/22</small>
                    </div>
                    <div class="input-group">
                      <span class="input-group-text"
                        ><i class="bi bi-credit-card"></i
                      ></span>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="cardNumber"
                        placeholder="Card Number Without Spaces"
                        formControlName="CardNumber"
                        maxlength="22"
                        (blur)="verifyCardNo()"
                        tabindex="2"
                      />
                    </div>
                    @if(!IsCardNumberValid && cardNumberNameControl.touched){
                    <small class="text-danger"> Card Number is invalid. </small>
                    } @if(cardNumberNameControl.touched &&
                    cardNumberNameControl.errors?.['required']){
                    <small class="text-danger">
                      Card Number is required.
                    </small>
                    }
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-end mb-2">
                  <div class="form-check form-switch">
                    <input
                      type="checkbox"
                      id="isDeposit"
                      class="form-check-input"
                      formControlName="EventDeposit"
                      (change)="eventDepostiChange()"
                    /><label for="isDeposit" class="form-check-label"
                      >Event Deposit/Installment?</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 mb-3">
                    <label for="cardExpDate" class="form-label"
                      >*Expiration Date</label
                    >
                    <div class="d-flex gap-1">
                      <select
                        formControlName="CardExpirationMonth"
                        class="form-select form-select-sm"
                        tabindex="4"
                      >
                        <option [ngValue]=""></option>
                        @for (item of expiredMonth; track item.Key){
                        <option [ngValue]="item.Key">
                          {{ item.Value }}
                        </option>
                        }
                      </select>
                      <select
                        formControlName="CardExpirationYear"
                        class="form-select form-select-sm"
                        tabindex="5"
                      >
                        <option [ngValue]=""></option>
                        @for (item of yearList; track item.Key){
                        <option [ngValue]="item.Key">
                          {{ item.Value }}
                        </option>
                        }
                      </select>
                    </div>
                    @if(expirationMonthControl.touched &&
                    expirationMonthControl.errors?.['required']
                    ||expirationYearControl.touched &&
                    expirationYearControl.errors?.['required'] ){
                    <small class="text-danger">Exp. Date is required. </small>
                    }
                  </div>
                  <div class="col-md-3 mb-3">
                    <label for="cardSecurityCode" class="form-label"
                      >Security Code</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="cardSecurityCode"
                      placeholder="CVV"
                      tabindex="6"
                      maxlength="4"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="PreAuthAmount" class="form-label"
                      >*Payment Amount
                    </label>
                    @if(preAuthorizeAmountVerify){
                    <i class="bi bi-exclamation-octagon text-danger me-2"></i>
                    }
                    <div class="input-group">
                      <span class="input-group-text"
                        ><i class="bi bi-currency-dollar"></i
                      ></span>
                      @if (!isEventDeposit) {
                      <input
                        class="form-control form-control-sm disabled"
                        [value]="preAuth.value"
                        tabindex="7"
                        min="0"
                        [disabled]="true"
                      />
                      }@else {
                      <input
                        type="number"
                        [ngClass]="{
                          'is-deposit': isEventDeposit
                        }"
                        class="form-control form-control-sm"
                        id="PreAuthAmount"
                        placeholder="Enter Amount"
                        formControlName="PreAuthAmount"
                        tabindex="7"
                        min="0"
                        (change)="changePayment($event)"
                      />
                      }
                    </div>
                    @if(bWrongPaymentAmountLess){
                    <small class="text-danger"
                      >Payment amount must be greater than zero.
                    </small>
                    } @if(bWrongPaymentAmountMore){
                    <small class="text-danger"
                      >Payment amount cannot be greater than Order Total
                      {{ order.NetBalanceDue | currency }}.
                    </small>
                    }
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-end">
                  <div class="form-check form-switch">
                    <input
                      tabindex="8"
                      type="checkbox"
                      id="isUsAddress"
                      class="form-check-input"
                      formControlName="UseUSAddress"
                      (change)="changeCountry()"
                    /><label for="isUsAddress" class="form-check-label"
                      >Is This US Address?</label
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label for="cardNumber" class="form-label"
                      >Street Address</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="StreetAddress"
                      placeholder="Enter Street Address"
                      formControlName="Street"
                      tabindex="9"
                      maxlength="50"
                    />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3 mt-3">
                    <label for="ZipCode" class="form-label"
                      >*Postal Code @if(bLoadingZipCodes){
                      <div
                        class="spinner-border text-primary custom-size"
                        role="status"
                      >
                        <span class="visually-hidden">loading...</span>
                      </div>
                      } @if(bWrongZip){
                      <i
                        class="bi bi-exclamation-octagon text-danger"
                        title="Invalid Zip Code"
                      ></i>
                      }
                    </label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="ZipCode"
                      placeholder="Enter Postal Code"
                      formControlName="ZipCode"
                      (change)="changeZipCode()"
                      maxlength="8"
                      tabindex="9"
                    />
                    @if(zipControl.touched && zipControl.errors?.['required']){
                    <small class="text-danger"> Zip Code is required. </small>
                    }
                  </div>
                  <div class="col-md-3 mt-3">
                    <label for="city" class="form-label">City</label>
                    @if(showCity){
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="city"
                      placeholder="Enter City"
                      formControlName="City"
                      tabindex="10"
                    />
                    }@else{
                    <select
                      formControlName="CityIndex"
                      class="form-select form-select-sm"
                      (change)="selectCity()"
                    >
                      @for (item of cityList; track item.Key){
                      <option [ngValue]="item.Key">
                        {{ item.Value }}
                      </option>
                      }
                    </select>
                    }
                  </div>
                  <div class="col-md-3 mt-3">
                    <label for="country" class="form-label">Country</label>
                    <select
                      id="country"
                      class="form-select form-select-sm"
                      formControlName="CountryId"
                      tabindex="10"
                    >
                      <option value="-1">Select Country</option>
                      @for (item of countriesList; track item.CountryID){
                      <option [ngValue]="item.CountryID">
                        {{ item.CountryName }}
                      </option>
                      }
                    </select>
                  </div>
                  <div class="col-md-3 mt-3">
                    <label for="cardNumber" class="form-label"
                      >State/Province</label
                    >
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="cardNumber"
                      placeholder="Enter State/Province"
                      formControlName="State"
                      tabindex="8"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-mobile d-flex gap-2 justify-content-center">
              <button
                tabindex="12"
                type="submit"
                [ngClass]="{
                  btn: true,
                  'btn-primary h-100-mobile': !ccInfoForm.valid,
                  'btn-success': ccInfoForm.valid
                }"
                [disabled]="bSaving || bLoadingZipCodes"
              >
                @if(bSaving){
                <span
                  class="spinner-grow spinner-grow-sm"
                  aria-hidden="true"
                ></span>
                <span role="status"> Processing Payment</span>
                }@else{ Submit Payment }
              </button>
            </div>
          </form>
        </div>
        }
      </div>
    </div>
    }}
  </div>
  <!-- Footer -->
  <app-godaddy-seal />
</div>
@if(showAgreementView){
<app-agreement-view (agreePayment)="agreePayment($event)"></app-agreement-view>
}
