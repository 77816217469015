import {
  ChangeDetectionStrategy,
  Component,
  EffectRef,
  OutputEmitterRef,
  WritableSignal,
  effect,
  output,
  signal,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-toggle-button',
  standalone: true,
  imports: [NgClass],
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent {
  onThemeChange: OutputEmitterRef<boolean> = output<boolean>({
    alias: 'themeChange',
  });

  constructor() {
    effect(() => {
      this.onThemeChange.emit(this.darkMode());
    });
  }
  // Start with light theme
  public darkMode: WritableSignal<boolean> = signal(false);

  changeTheme(): void {
    this.darkMode.update((darkMode: boolean) => (darkMode = !darkMode));
  }
}
