import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiParams, ApiResponseType } from '../Models/api';
type All = string | boolean | number | Date | null | undefined;
type ApiShape = Extract<All, string | boolean | number | Date> | void | object;
import { getApiDateString } from 'src/app/Core/Helper/common-helpers';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  get<T extends ApiShape>(
    token: string,
    url: string,
    params: ApiParams | null = null,
    response: ApiResponseType = ApiResponseType.Json
  ): Observable<T> {
    const requestOptions: Object = {
      params: this.getHttpParams(params),
      responseType: response,
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<T>(this.getUrl(url), requestOptions);
  }
  getAzure<T extends ApiShape>(
    url: string,
    params: ApiParams | null = null,
    response: ApiResponseType = ApiResponseType.Json
  ): Observable<T> {
    const requestOptions: Object = {
      params: this.getHttpParams(params),
      responseType: response,
    };
    return this.http.get<T>(url, requestOptions);
  }
  post<T extends ApiShape>(
    token: string = '',
    url: string,
    data: any,
    params: ApiParams | null = null
  ): Observable<T> {
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    return this.http.post<T>(this.getUrl(url), data, {
      params: this.getHttpParams(params),
      headers: headers,
    });
  }
  private getHttpParams(params: ApiParams | null = null): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        const value: any = getApiDateString(params[key]);
        if (value !== null && value !== undefined)
          httpParams = httpParams.append(key, value.toString());
      });
    }
    return httpParams;
  }
  private getUrl(url: string): string {
    return `${'api'}/${url}`;
  }
}
