import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private readonly apiUrl: string = environment.apiUrl;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Modify or add headers, handle errors, etc. here

    const secureHeaders = {
      'Encore-API': environment.siteKey,
    };
    const modifiedReq = request.clone({
      url: !request.url.includes('azurewebsites')
        ? this.apiUrl + request.url
        : request.url,
      setHeaders: secureHeaders,
    });
    return next.handle(modifiedReq).pipe(
      catchError((err) => {
        return this.handleError(err);
      })
    );
  }

  private handleError(response: HttpErrorResponse): Observable<never> {
    if (response.status === 401) {
      console.error(
        'Error 401: Authorization has been denied for this request.'
      );
    } else {
      console.error('Server Error!', response.error);
    }
    return throwError(() => response);
  }
}
