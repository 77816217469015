<button class="round-button" title="Toggle Theme">
  <i
    [ngClass]="{
      bi: true,
      'bi-moon': !darkMode(),
      'bi-brightness-high': darkMode()
    }"
    (click)="changeTheme()"
  ></i>
</button>
