<p-dialog
  [visible]="true"
  [modal]="true"
  [closable]="false"
  [resizable]="false"
  [showHeader]="false"
  contentStyleClass="dialog-sm"
  [style]="{ width: '550px' }"
  [breakpoints]="{ '1199px': '50vw', '575px': '75vw' }"
  position="center"
>
  <div class="dialog-header">
    <i class="bi bi-info-circle ps-2"></i>
    Disclaimer
  </div>
  <div class="dialog-body">
    <p class="text-center">
      By clicking the Agree button, you agree to a hold being placed on your
      card for the payment amount set forth above.<br />
      The actual amount charged to your card will be equal to the total agreed
      fees set forth on the final order and will be processed upon conclusion of
      the event.
    </p>
  </div>
  <div class="dialog-footer">
    <hr />
    <button type="button" class="btn btn-primary" (click)="onAgree(true)">
      Agree
    </button>
    <button type="button" class="btn btn-warning" (click)="onAgree(false)">
      Discard
    </button>
  </div>
</p-dialog>
