import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { Order, ShipAddress } from '../Models/order';
import { Country, CreditCardPayments } from '../Models/payment';
import { ApiParams } from '../Models/api';
import { KeyValueModel } from '../Models/keyValue';
@Injectable({
  providedIn: 'root',
})
// src\app\Shared\Services\order.service.ts
export class OrderService {
  constructor(private readonly httpService: HttpService) {}
  public accessToken: string = '';

  get countriesList(): Country[] {
    return [
      {
        CountryID: 480,
        CountryName: 'Afghanistan',
        CountryShortName: 'AF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 713,
        CountryName: 'Aland Islands',
        CountryShortName: 'AX',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 483,
        CountryName: 'Albania',
        CountryShortName: 'AL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 532,
        CountryName: 'Algeria',
        CountryShortName: 'DZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 489,
        CountryName: 'American Samoa',
        CountryShortName: 'AS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 478,
        CountryName: 'Andorra',
        CountryShortName: 'AD',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 486,
        CountryName: 'Angola',
        CountryShortName: 'AO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 482,
        CountryName: 'Anguilla',
        CountryShortName: 'AI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 487,
        CountryName: 'Antarctica',
        CountryShortName: 'AQ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 481,
        CountryName: 'Antigua And Barbuda',
        CountryShortName: 'AG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 488,
        CountryName: 'Argentina',
        CountryShortName: 'AR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 484,
        CountryName: 'Armenia',
        CountryShortName: 'AM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 492,
        CountryName: 'Aruba',
        CountryShortName: 'AW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 477,
        CountryName: 'Ascension Island',
        CountryShortName: 'AC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 491,
        CountryName: 'Australia',
        CountryShortName: 'AU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 490,
        CountryName: 'Austria',
        CountryShortName: 'AT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 493,
        CountryName: 'Azerbaijan',
        CountryShortName: 'AZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 505,
        CountryName: 'Bahamas',
        CountryShortName: 'BS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 498,
        CountryName: 'Bahrain',
        CountryShortName: 'BH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 714,
        CountryName: 'Bangladesh',
        CountryShortName: 'BD',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 495,
        CountryName: 'Barbados',
        CountryShortName: 'BB',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 509,
        CountryName: 'Belarus',
        CountryShortName: 'BY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 8,
        CountryName: 'Belgium',
        CountryShortName: 'BE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 510,
        CountryName: 'Belize',
        CountryShortName: 'BZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 500,
        CountryName: 'Benin',
        CountryShortName: 'BJ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 501,
        CountryName: 'Bermuda',
        CountryShortName: 'BM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 506,
        CountryName: 'Bhutan',
        CountryShortName: 'BT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 503,
        CountryName: 'Bolivia',
        CountryShortName: 'BO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 494,
        CountryName: 'Bosnia And Herzegowina',
        CountryShortName: 'BA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 508,
        CountryName: 'Botswana',
        CountryShortName: 'BW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 507,
        CountryName: 'Bouvet Island',
        CountryShortName: 'BV',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 504,
        CountryName: 'Brazil',
        CountryShortName: 'BR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 574,
        CountryName: 'British Indian Ocean Territory',
        CountryShortName: 'IO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 502,
        CountryName: 'Brunei Darussalam',
        CountryShortName: 'BN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 497,
        CountryName: 'Bulgaria',
        CountryShortName: 'BG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 496,
        CountryName: 'Burkina Faso',
        CountryShortName: 'BF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 499,
        CountryName: 'Burundi',
        CountryShortName: 'BI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 584,
        CountryName: 'Cambodia',
        CountryShortName: 'KH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 519,
        CountryName: 'Cameroon',
        CountryShortName: 'CM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 4,
        CountryName: 'Canada',
        CountryShortName: 'CA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 524,
        CountryName: 'Cape Verde',
        CountryShortName: 'CV',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 590,
        CountryName: 'Cayman Islands',
        CountryShortName: 'KY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 513,
        CountryName: 'Central African Republic',
        CountryShortName: 'CF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 675,
        CountryName: 'Chad',
        CountryShortName: 'TD',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 518,
        CountryName: 'Chile',
        CountryShortName: 'CL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 520,
        CountryName: 'China',
        CountryShortName: 'CN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 525,
        CountryName: 'Christmas Island',
        CountryShortName: 'CX',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 511,
        CountryName: 'Cocos (Keeling) Islands',
        CountryShortName: 'CC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 521,
        CountryName: 'Colombia',
        CountryShortName: 'CO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 586,
        CountryName: 'Comoros',
        CountryShortName: 'KM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 512,
        CountryName: 'Congo (Democratic Republic)',
        CountryShortName: 'CD',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 514,
        CountryName: 'Congo (Republic)',
        CountryShortName: 'CG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 517,
        CountryName: 'Cook Islands',
        CountryShortName: 'CK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 522,
        CountryName: 'Costa Rica',
        CountryShortName: 'CR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 516,
        CountryName: "Cote D'Ivoire",
        CountryShortName: 'CI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 566,
        CountryName: 'Croatia (local name: Hrvatska)',
        CountryShortName: 'HR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 523,
        CountryName: 'Cuba',
        CountryShortName: 'CU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 711,
        CountryName: 'Curacao',
        CountryShortName: 'CW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 526,
        CountryName: 'Cyprus',
        CountryShortName: 'CY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 527,
        CountryName: 'Czech Republic',
        CountryShortName: 'CZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 529,
        CountryName: 'Denmark',
        CountryShortName: 'DK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 528,
        CountryName: 'Djibouti',
        CountryShortName: 'DJ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 530,
        CountryName: 'Dominica',
        CountryShortName: 'DM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 531,
        CountryName: 'Dominican Republic',
        CountryShortName: 'DO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 533,
        CountryName: 'Ecuador',
        CountryShortName: 'EC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 535,
        CountryName: 'Egypt',
        CountryShortName: 'EG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 671,
        CountryName: 'El Salvador',
        CountryShortName: 'SV',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 556,
        CountryName: 'Equatorial Guinea',
        CountryShortName: 'GQ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 536,
        CountryName: 'Eritrea',
        CountryShortName: 'ER',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 534,
        CountryName: 'Estonia',
        CountryShortName: 'EE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 538,
        CountryName: 'Ethiopia',
        CountryShortName: 'ET',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 541,
        CountryName: 'Falkland Islands (Malvinas)',
        CountryShortName: 'FK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 543,
        CountryName: 'Faroe Islands',
        CountryShortName: 'FO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 540,
        CountryName: 'Fiji',
        CountryShortName: 'FJ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 539,
        CountryName: 'Finland',
        CountryShortName: 'FI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 7,
        CountryName: 'France',
        CountryShortName: 'FR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 548,
        CountryName: 'French Guiana',
        CountryShortName: 'GF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 638,
        CountryName: 'French Polynesia',
        CountryShortName: 'PF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 676,
        CountryName: 'French Southern Territories',
        CountryShortName: 'TF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 544,
        CountryName: 'Gabon',
        CountryShortName: 'GA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 553,
        CountryName: 'Gambia',
        CountryShortName: 'GM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 547,
        CountryName: 'Georgia',
        CountryShortName: 'GE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 6,
        CountryName: 'Germany',
        CountryShortName: 'DE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 550,
        CountryName: 'Ghana',
        CountryShortName: 'GH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 551,
        CountryName: 'Gibraltar',
        CountryShortName: 'GI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 557,
        CountryName: 'Greece',
        CountryShortName: 'GR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 552,
        CountryName: 'Greenland',
        CountryShortName: 'GL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 546,
        CountryName: 'Grenada',
        CountryShortName: 'GD',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 555,
        CountryName: 'Guadeloupe',
        CountryShortName: 'GP',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 560,
        CountryName: 'Guam',
        CountryShortName: 'GU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 559,
        CountryName: 'Guatemala',
        CountryShortName: 'GT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 549,
        CountryName: 'Guernsey',
        CountryShortName: 'GG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 554,
        CountryName: 'Guinea',
        CountryShortName: 'GN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 561,
        CountryName: 'Guinea-Bissau',
        CountryShortName: 'GW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 562,
        CountryName: 'Guyana',
        CountryShortName: 'GY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 567,
        CountryName: 'Haiti',
        CountryShortName: 'HT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 564,
        CountryName: 'Heard And Mc Donald Islands',
        CountryShortName: 'HM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 565,
        CountryName: 'Honduras',
        CountryShortName: 'HN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 563,
        CountryName: 'Hong Kong',
        CountryShortName: 'HK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 568,
        CountryName: 'Hungary',
        CountryShortName: 'HU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 577,
        CountryName: 'Iceland',
        CountryShortName: 'IS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 573,
        CountryName: 'India',
        CountryShortName: 'IN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 569,
        CountryName: 'Indonesia',
        CountryShortName: 'ID',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 576,
        CountryName: 'Iran (Islamic Republic Of)',
        CountryShortName: 'IR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 575,
        CountryName: 'Iraq',
        CountryShortName: 'IQ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 570,
        CountryName: 'Ireland',
        CountryShortName: 'IE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 572,
        CountryName: 'Isle of Man',
        CountryShortName: 'IM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 571,
        CountryName: 'Israel',
        CountryShortName: 'IL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 578,
        CountryName: 'Italy',
        CountryShortName: 'IT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 580,
        CountryName: 'Jamaica',
        CountryShortName: 'JM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 5,
        CountryName: 'Japan',
        CountryShortName: 'JP',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 579,
        CountryName: 'Jersey',
        CountryShortName: 'JE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 581,
        CountryName: 'Jordan',
        CountryShortName: 'JO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 591,
        CountryName: 'Kazakhstan',
        CountryShortName: 'KZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 582,
        CountryName: 'Kenya',
        CountryShortName: 'KE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 585,
        CountryName: 'Kiribati',
        CountryShortName: 'KI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 719,
        CountryName: 'Korea',
        CountryShortName: 'KP',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 588,
        CountryName: 'Korea, Republic Of',
        CountryShortName: 'KR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 589,
        CountryName: 'Kuwait',
        CountryShortName: 'KW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 583,
        CountryName: 'Kyrgyzstan',
        CountryShortName: 'KG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 592,
        CountryName: "Lao People's Democratic Republic",
        CountryShortName: 'LA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 601,
        CountryName: 'Latvia',
        CountryShortName: 'LV',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 593,
        CountryName: 'Lebanon',
        CountryShortName: 'LB',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 598,
        CountryName: 'Lesotho',
        CountryShortName: 'LS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 597,
        CountryName: 'Liberia',
        CountryShortName: 'LR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 602,
        CountryName: 'Libyan Arab Jamahiriya',
        CountryShortName: 'LY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 595,
        CountryName: 'Liechtenstein',
        CountryShortName: 'LI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 599,
        CountryName: 'Lithuania',
        CountryShortName: 'LT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 600,
        CountryName: 'Luxembourg',
        CountryShortName: 'LU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 612,
        CountryName: 'Macau',
        CountryShortName: 'MO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 608,
        CountryName: 'Macedonia, The Former Yugoslav Republic Of',
        CountryShortName: 'MK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 606,
        CountryName: 'Madagascar',
        CountryShortName: 'MG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 620,
        CountryName: 'Malawi',
        CountryShortName: 'MW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 621,
        CountryName: 'Malaysia',
        CountryShortName: 'MY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 619,
        CountryName: 'Maldives',
        CountryShortName: 'MV',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 609,
        CountryName: 'Mali',
        CountryShortName: 'ML',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 617,
        CountryName: 'Malta',
        CountryShortName: 'MT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 607,
        CountryName: 'Marshall Islands',
        CountryShortName: 'MH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 614,
        CountryName: 'Martinique',
        CountryShortName: 'MQ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 615,
        CountryName: 'Mauritania',
        CountryShortName: 'MR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 618,
        CountryName: 'Mauritius',
        CountryShortName: 'MU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 705,
        CountryName: 'Mayotte',
        CountryShortName: 'YT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 3,
        CountryName: 'Mexico',
        CountryShortName: 'MX',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 542,
        CountryName: 'Micronesia, Federated States Of',
        CountryShortName: 'FM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 605,
        CountryName: 'Moldova, Republic Of',
        CountryShortName: 'MD',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 604,
        CountryName: 'Monaco',
        CountryShortName: 'MC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 611,
        CountryName: 'Mongolia',
        CountryShortName: 'MN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 721,
        CountryName: 'Montenegro',
        CountryShortName: 'ME',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 616,
        CountryName: 'Montserrat',
        CountryShortName: 'MS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 603,
        CountryName: 'Morocco',
        CountryShortName: 'MA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 622,
        CountryName: 'Mozambique',
        CountryShortName: 'MZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 610,
        CountryName: 'Myanmar',
        CountryShortName: 'MM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 623,
        CountryName: 'Namibia',
        CountryShortName: 'NA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 632,
        CountryName: 'Nauru',
        CountryShortName: 'NR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 631,
        CountryName: 'Nepal',
        CountryShortName: 'NP',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 629,
        CountryName: 'Netherlands',
        CountryShortName: 'NL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 485,
        CountryName: 'Netherlands Antilles',
        CountryShortName: 'AN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 624,
        CountryName: 'New Caledonia',
        CountryShortName: 'NC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 634,
        CountryName: 'New Zealand',
        CountryShortName: 'NZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 628,
        CountryName: 'Nicaragua',
        CountryShortName: 'NI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 625,
        CountryName: 'Niger',
        CountryShortName: 'NE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 627,
        CountryName: 'Nigeria',
        CountryShortName: 'NG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 633,
        CountryName: 'Niue',
        CountryShortName: 'NU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 626,
        CountryName: 'Norfolk Island',
        CountryShortName: 'NF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 613,
        CountryName: 'Northern Mariana Islands',
        CountryShortName: 'MP',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 630,
        CountryName: 'Norway',
        CountryShortName: 'NO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 718,
        CountryName: 'Obsolete see FR territory',
        CountryShortName: 'FX',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 720,
        CountryName: 'Obsolete see LT territory',
        CountryShortName: 'LX',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 716,
        CountryName: 'Obsolete see RS or ME territory',
        CountryShortName: 'CS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 635,
        CountryName: 'Oman',
        CountryShortName: 'OM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 641,
        CountryName: 'Pakistan',
        CountryShortName: 'PK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 647,
        CountryName: 'Palau',
        CountryShortName: 'PW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 723,
        CountryName: 'Palestinian Territory',
        CountryShortName: 'PS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 636,
        CountryName: 'Panama',
        CountryShortName: 'PA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 639,
        CountryName: 'Papua New Guinea',
        CountryShortName: 'PG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 648,
        CountryName: 'Paraguay',
        CountryShortName: 'PY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 637,
        CountryName: 'Peru',
        CountryShortName: 'PE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 640,
        CountryName: 'Philippines',
        CountryShortName: 'PH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 644,
        CountryName: 'Pitcairn',
        CountryShortName: 'PN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 642,
        CountryName: 'Poland',
        CountryShortName: 'PL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 646,
        CountryName: 'Portugal',
        CountryShortName: 'PT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 645,
        CountryName: 'Puerto Rico',
        CountryShortName: 'PR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 649,
        CountryName: 'Qatar',
        CountryShortName: 'QA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 650,
        CountryName: 'Reunion',
        CountryShortName: 'RE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 651,
        CountryName: 'Romania',
        CountryShortName: 'RO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 652,
        CountryName: 'Russian Federation',
        CountryShortName: 'RU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 653,
        CountryName: 'Rwanda',
        CountryShortName: 'RW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 715,
        CountryName: 'Saint Barthelemy',
        CountryShortName: 'BL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 587,
        CountryName: 'Saint Kitts And Nevis',
        CountryShortName: 'KN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 594,
        CountryName: 'Saint Lucia',
        CountryShortName: 'LC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 722,
        CountryName: 'Saint Martin (French part)',
        CountryShortName: 'MF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 696,
        CountryName: 'Saint Vincent And The Grenadines',
        CountryShortName: 'VC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 703,
        CountryName: 'Samoa',
        CountryShortName: 'WS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 665,
        CountryName: 'San Marino',
        CountryShortName: 'SM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 669,
        CountryName: 'Sao Tome And Principe',
        CountryShortName: 'ST',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 654,
        CountryName: 'Saudi Arabia',
        CountryShortName: 'SA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 666,
        CountryName: 'Senegal',
        CountryShortName: 'SN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 724,
        CountryName: 'Serbia',
        CountryShortName: 'RS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 656,
        CountryName: 'Seychelles',
        CountryShortName: 'SC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 664,
        CountryName: 'Sierra Leone',
        CountryShortName: 'SL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 659,
        CountryName: 'Singapore',
        CountryShortName: 'SG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 712,
        CountryName: 'Sint Maarten',
        CountryShortName: 'SX',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 663,
        CountryName: 'Slovakia (Slovak Republic)',
        CountryShortName: 'SK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 661,
        CountryName: 'Slovenia',
        CountryShortName: 'SI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 655,
        CountryName: 'Solomon Islands',
        CountryShortName: 'SB',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 667,
        CountryName: 'Somalia',
        CountryShortName: 'SO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 707,
        CountryName: 'South Africa',
        CountryShortName: 'ZA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 558,
        CountryName: 'South Georgia And The South Sandwich Islands',
        CountryShortName: 'GS',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 670,
        CountryName: 'Soviet Union (obsoloete)',
        CountryShortName: 'SU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 537,
        CountryName: 'Spain',
        CountryShortName: 'ES',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 596,
        CountryName: 'Sri Lanka',
        CountryShortName: 'LK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 660,
        CountryName: 'St. Helena',
        CountryShortName: 'SH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 643,
        CountryName: 'St. Pierre And Miquelon',
        CountryShortName: 'PM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 657,
        CountryName: 'Sudan',
        CountryShortName: 'SD',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 668,
        CountryName: 'Suriname',
        CountryShortName: 'SR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 662,
        CountryName: 'Svalbard And Jan Mayen Islands',
        CountryShortName: 'SJ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 673,
        CountryName: 'Swaziland',
        CountryShortName: 'SZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 658,
        CountryName: 'Sweden',
        CountryShortName: 'SE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 515,
        CountryName: 'Switzerland',
        CountryShortName: 'CH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 672,
        CountryName: 'Syrian Arab Republic',
        CountryShortName: 'SY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 688,
        CountryName: 'Taiwan, Province Of China',
        CountryShortName: 'TW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 679,
        CountryName: 'Tajikistan',
        CountryShortName: 'TJ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 689,
        CountryName: 'Tanzania, United Republic Of',
        CountryShortName: 'TZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 678,
        CountryName: 'Thailand',
        CountryShortName: 'TH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 725,
        CountryName: 'Timor-Leste',
        CountryShortName: 'TL',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 677,
        CountryName: 'Togo',
        CountryShortName: 'TG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 680,
        CountryName: 'Tokelau',
        CountryShortName: 'TK',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 683,
        CountryName: 'Tonga',
        CountryShortName: 'TO',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 686,
        CountryName: 'Trinidad And Tobago',
        CountryShortName: 'TT',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 682,
        CountryName: 'Tunisia',
        CountryShortName: 'TN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 685,
        CountryName: 'Turkey',
        CountryShortName: 'TR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 681,
        CountryName: 'Turkmenistan',
        CountryShortName: 'TM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 674,
        CountryName: 'Turks And Caicos Islands',
        CountryShortName: 'TC',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 687,
        CountryName: 'Tuvalu',
        CountryShortName: 'TV',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 691,
        CountryName: 'Uganda',
        CountryShortName: 'UG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 690,
        CountryName: 'Ukraine',
        CountryShortName: 'UA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 479,
        CountryName: 'United Arab Emirates',
        CountryShortName: 'AE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 2,
        CountryName: 'United Kingdom',
        CountryShortName: 'GB',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 1,
        CountryName: 'United States',
        CountryShortName: 'US',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 692,
        CountryName: 'United States Minor Outlying Islands',
        CountryShortName: 'UM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 693,
        CountryName: 'Uruguay',
        CountryShortName: 'UY',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 694,
        CountryName: 'Uzbekistan',
        CountryShortName: 'UZ',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 701,
        CountryName: 'Vanuatu',
        CountryShortName: 'VU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 695,
        CountryName: 'Vatican City State (Holy See)',
        CountryShortName: 'VA',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 697,
        CountryName: 'Venezuela',
        CountryShortName: 'VE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 700,
        CountryName: 'Viet Nam',
        CountryShortName: 'VN',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 698,
        CountryName: 'Virgin Islands (British)',
        CountryShortName: 'VG',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 699,
        CountryName: 'Virgin Islands (U.S.)',
        CountryShortName: 'VI',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 702,
        CountryName: 'Wallis And Futuna Islands',
        CountryShortName: 'WF',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 717,
        CountryName: 'Western Sahara',
        CountryShortName: 'EH',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 704,
        CountryName: 'Yemen',
        CountryShortName: 'YE',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 706,
        CountryName: 'Yugoslavia',
        CountryShortName: 'YU',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 709,
        CountryName: 'Zaire',
        CountryShortName: 'ZR',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 708,
        CountryName: 'Zambia',
        CountryShortName: 'ZM',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
      {
        CountryID: 710,
        CountryName: 'Zimbabwe',
        CountryShortName: 'ZW',
        IsNew: false,
        IsDeleted: false,
        IsDirty: false,
        IsValid: false,
      },
    ];
  }

  getExpiredMonth(): KeyValueModel[] {
    let Months: KeyValueModel[] = [];
    for (let i = 1; i <= 12; i++) {
      Months.push({ Key: i, Value: i.toString().padStart(2, '0') });
    }
    return Months;
  }
  getExpiredYear(paymentyear: string): KeyValueModel[] {
    let Years = [];
    if (paymentyear == '') {
      let thisyear = new Date().getFullYear();
      for (let year = thisyear; year <= thisyear + 15; year++)
        Years.push({ Key: year, Value: year });
    } else Years.push({ Key: paymentyear, Value: paymentyear });
    return Years;
  }
  getOrder(locationId: string, orderNumber: string): Observable<Order> {
    // Pass the token to the getOrder call
    return this.httpService.get<Order>(this.accessToken, 'Order/GetPayment', {
      locationId: locationId,
      orderNumber: orderNumber,
      culture: 'en-US',
    });
  }

  getAddressListByZipCode(zipcode: string): Observable<ShipAddress[]> {
    return this.httpService.get<ShipAddress[]>(
      this.accessToken,
      'ShippingAddress',
      {
        zipcode: zipcode,
        isCountry: true,
      }
    );
  }
  // Get Countries from Azure but it's not being used now
  // There is a local function frin order.service to get the supported countries
  getCountries(country: ApiParams | null = null): Observable<Country[]> {
    return this.httpService.getAzure<Country[]>(
      'https://getlocationslist.azurewebsites.net/api/GetCountries',
      country
    );
  }

  getCreditPaymentInfo(
    isCredit: boolean,
    edittype: string,
    orderguid: string,
    paymentId: string,
    locationid: string
  ): Observable<CreditCardPayments> {
    return this.httpService.get<CreditCardPayments>(
      this.accessToken,
      `Payment`,
      {
        isCredit: isCredit,
        edittype: edittype,
        orderGuid: orderguid,
        paymentId: paymentId,
        locationId: locationid,
        culture: 'en-US',
      }
    );
  }
  savePayment(payment: CreditCardPayments): Observable<string> {
    return this.httpService.post<string>(
      this.accessToken,
      `Payment/SavePayment`,
      payment
    );
  }
}
