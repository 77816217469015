import { CurrencyPipe, DatePipe, NgClass } from '@angular/common';
import {
  Component,
  DestroyRef,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { finalize, take } from 'rxjs';
import { OrderService } from 'src/app/Shared/Services/order.service';
import { Params } from '@angular/router';
// import { NgxCaptchaModule } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms'; // Reactive approach
import { ReactiveFormsModule } from '@angular/forms';
import { KeyValueModel } from 'src/app/Shared/Models/keyValue';
import {
  encryptString,
  getJwtExpirationDate,
  getJwtLocation,
  getJwtOrderNumber,
  isDateExpired,
} from 'src/app/Core/Helper/common-helpers';
import { Order, ShipAddress } from 'src/app/Shared/Models/order';
import {
  CAPTURE,
  EMPTY_GUID,
  PRE_AUTHORIZE,
  VALIDATE,
  PAYMETECH_ENDPOINT_DOWN,
  VALIDATE_DECLINE,
  VOID_DECLINE,
  PRE_AUTHORIZE_DECLINE,
  CAPTURE_DECLINE,
  VERIFY_PRE_AUTH_AMOUNT,
  PRE_AUTHORIZE_APPROVE,
  CAPTURE_APPROVE,
  AMOUNT_MISMATCHED,
  INVALID_CARD_NUMBER,
} from 'src/app/Core/constants/process';
import { Country, CreditCardPayments } from 'src/app/Shared/Models/payment';
import { LoadingComponent } from '../loading/loading.component';
import { ErrorComponent } from '../error/error.component';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { ToggleButtonComponent } from '../toggle-button/toggle-button.component';
import { GodaddySealComponent } from '../godaddy-seal/godaddy-seal.component';
import { OrderDetailViewComponent } from '../order-detail-view/order-detail-view.component';
import { AgreementViewComponent } from '../agreement-view/agreement-view.component';
import { AmountMismatchedViewComponent } from '../amount-mismatch-view/amount-mismatch-view.component';
type theme = 'dark' | 'light';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    CurrencyPipe,
    DatePipe,
    NgClass,
    ReactiveFormsModule,
    LoadingComponent,
    ErrorComponent,
    // NgxCaptchaModule,
    PageHeaderComponent,
    ToggleButtonComponent,
    GodaddySealComponent,
    OrderDetailViewComponent,
    AgreementViewComponent,
    AmountMismatchedViewComponent,
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent implements OnInit, OnDestroy {
  // Route Param from URL - Token
  @Input({ required: true }) token: string = '';
  destroyRef = inject(DestroyRef);
  // Supported Countries
  countriesList: Country[] = this.orderService.countriesList;
  errorCode: number = 0;

  public theme: theme = 'light';
  public IsCardNumberValid: boolean = false;

  public ccLength: number = 0;
  public expiredMonth: KeyValueModel[] = this.orderService.getExpiredMonth();
  public yearList: KeyValueModel[] = this.orderService.getExpiredYear('');
  initialLoad: boolean = true;
  cityList: KeyValueModel[] = [];
  USAddressData: ShipAddress[] = [];
  countries: Country[] = [];
  isEmailValid: boolean = true;
  bLoadingZipCodes: boolean = false;
  bWrongZip: boolean = false;
  siteKey: string = environment.siteKey;

  bWrongPaymentAmountLess: boolean = false;
  bWrongPaymentAmountMore: boolean = false;
  transType: string = CAPTURE;
  validationError: boolean = false;
  preAuthorizeError: boolean = false;
  alreadyPreAuthorized: boolean = false;
  captureError: boolean = false;
  isError: boolean = false;
  zipcodeVerify: boolean = false;
  showCity: boolean = true;
  bExpiredLink: boolean = false;
  payment: CreditCardPayments = {} as CreditCardPayments;
  loading: boolean = false;
  bSaving: boolean = false;

  usAddress: boolean = false;
  error: string = '';
  processingError: string = '';
  expirationError: boolean = false;
  preAuthorizeAmountVerify: boolean = false;
  alreadyCaptured: boolean = false;
  remainingbalancedue: number = 0;
  CRBError: boolean = false;
  ccInfoForm: FormGroup = {} as FormGroup;
  order: Order = {} as Order;
  showAgreementView = false;
  isAmountMismatched = false;
  constructor(
    private readonly orderService: OrderService,
    private readonly fb: FormBuilder
  ) {}
  // Caprcha

  selectCity(): void {
    const selectedIndex = this.ccInfoForm.get('CityIndex')?.value;
    if (selectedIndex != -1) {
      let data = this.USAddressData[selectedIndex];

      this.ccInfoForm.get('State')?.setValue(data.State);
      this.ccInfoForm.get('City')?.setValue(data.City);
      this.payment.ZipCodeId = data.ZipCodeID;
    }
  }
  changeTheme(mode: boolean): void {
    const htmlElement = document.querySelector('html')!;
    if (mode) {
      htmlElement.setAttribute('data-dark-mode', 'dark-mode');
    } else {
      htmlElement.removeAttribute('data-dark-mode');
    }

    this.theme = mode ? 'dark' : 'light';
  }
  verifyCardNo(): void {
    const cardNumber = this.ccInfoForm.get('CardNumber')?.value.toString();
    const pattern = /^\d+$/;

    if (!pattern.test(cardNumber)) {
      this.IsCardNumberValid = false;
      return;
    }

    this.validateCreditCard(cardNumber);
  }
  createCityList(addressList: ShipAddress[]): KeyValueModel[] {
    let cityList: KeyValueModel[] = [];
    let index = 0;
    this.ccInfoForm.get('State')?.setValue(addressList[0].State);
    addressList.forEach((address) => {
      cityList.push({ Key: index, Value: address.City });
      index++;
    });
    return cityList;
  }

  getOrder(LocationId: string, orderNum: string): void {
    this.loading = true;
    this.orderService
      .getOrder(LocationId, orderNum)
      .pipe(
        finalize(() => {
          if (!this.error) {
            this.setEmailAddress(this.order.BillToContactEmail);
            this.getPaymentData();
          }
        }),
        take(1)
      )
      .subscribe({
        next: (value) => {
          this.order = value;
          this.remainingbalancedue = this.order.NetBalanceDue;
          if (this.remainingbalancedue <= 0) {
            this.changeView();
          }
          this.setPaymentType(value.Status);
        },
        error: () => {
          this.error = 'Provide Link is not valid';
          this.loading = false;
          this.errorCode = 200;
        },
      });
  }
  validateForm(): boolean {
    let errorDetected: boolean = false;
    Object.keys(this.ccInfoForm.controls).forEach((key) => {
      const controlErrors = this.ccInfoForm.get(key)?.errors;
      if (controlErrors) {
        this.ccInfoForm.get(key)?.markAsTouched();
        errorDetected = true;
      }
    });

    if (
      this.ccInfoForm.invalid ||
      !this.isEmailValid ||
      !this.isAddressCorrect ||
      this.bWrongPaymentAmountLess ||
      this.bWrongPaymentAmountMore ||
      !this.IsCardNumberValid
    ) {
      errorDetected = true;
    }

    return errorDetected ? false : true;
  }

  private getPaymentData(): void {
    // no need to run this if order is already paid
    // we are only starting the payment confirmation section
    if (this.order.Paid) {
      this.loading = false;
      return;
    }
    this.orderService
      .getCreditPaymentInfo(
        true,
        this.order.Paid ? 'edit' : 'new',
        this.order.Id,
        EMPTY_GUID,
        this.order.LocalOfficeId
      )
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.payment = data as CreditCardPayments;
          this.payment.UseUSAddress = !this.payment.UseInternationalAddress;
          this.payment.IsCardNumberValid = true;
          this.payment.CityIndex = -1;
          this.payment.IsSecurityDeposit = false;
          this.payment.CardExpirationMonth = 0;
          this.payment.CardExpirationYear = 0;
          this.setFormValues();
          this.loading = false;
        },
        error: (err) => {
          console.error(err);
          this.error = 'Provide Link is not valid';
          this.loading = false;
        },
      });
  }

  changePayment(event: any): void {
    this.bWrongPaymentAmountLess = false;
    this.bWrongPaymentAmountMore = false;

    const val = event.target.value;
    if (val <= 0) {
      console.error('Wrong amount');
      this.bWrongPaymentAmountLess = true;
    }
    if (val > this.order.NetBalanceDue) {
      console.error('Wrong amount');
      this.bWrongPaymentAmountMore = true;
    }
  }
  operatePayment(transType: string) {
    this.payment.TransactionList = [];
    this.payment.UpdatedBy = 'customer';

    //countryId may be 0 in the form, we need to set it to correct value
    this.ccInfoForm.get('CountryId')?.setValue(this.payment.CountryId);

    this.validationError = false;
    this.preAuthorizeError = false;
    this.alreadyPreAuthorized = false;
    this.captureError = false;
    this.expirationError = false;
    this.preAuthorizeAmountVerify = false;
    this.CRBError = false;

    let _selectedCountry: Country = this.countriesList.find(
      (country) => country.CountryID === this.payment.CountryId
    )!;

    this.payment = {
      ...this.payment,
      Country: _selectedCountry,
    };

    const chargeRemainingBalance = this.ccInfoForm.get(
      'ChargeRemainingBalance'
    )?.value;
    const preAuthAmount = this.ccInfoForm.get('PreAuthAmount')?.value;
    if (chargeRemainingBalance) {
      if (
        transType == PRE_AUTHORIZE ||
        transType == CAPTURE ||
        transType == VALIDATE
      ) {
        if (this.remainingbalancedue > 0) {
          this.payment.PaymentAmount =
            this.remainingbalancedue + this.payment.PaymentAmount;
        }
        if (preAuthAmount == 0) {
          this.payment.PreAuthAmount = this.payment.PaymentAmount;
          this.ccInfoForm
            .get('PreAuthAmount')
            ?.setValue(this.payment.PreAuthAmount);
        }

        if (this.remainingbalancedue <= 0) {
          this.payment.PaymentAmount = 0;
        } else {
          this.payment.PaymentAmount =
            this.ccInfoForm.get('PreAuthAmount')?.value;
        }
      }
    } else this.payment.PaymentAmount = preAuthAmount;

    this.payment.TransactionType = transType;

    const cardNumber = this.ccInfoForm.get('CardNumber')?.value;
    this.payment.CCBin = cardNumber.substring(0, 1);
    this.payment.CCLast4 = cardNumber.substring(
      cardNumber.length - 4,
      cardNumber.length
    );
  }

  validateEmail(): void {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.isEmailValid = emailRegex.test(this.emailControl.value);
  }
  resetErrors(): void {
    this.error = '';
    this.validationError = false;
    this.preAuthorizeError = false;
    this.alreadyPreAuthorized = false;
    this.captureError = false;
    this.expirationError = false;
    this.preAuthorizeAmountVerify = false;
    this.CRBError = false;
    this.processingError = '';
    this.errorCode = 0;
  }
  refreshPage() {
    location.reload();
  }
  payNow(): void {
    if (this.bSaving) return;
    if (this.ccInfoForm.getRawValue().EventDeposit) {
      this.processPayment();
    } else {
      this.showAgreementView = true;
    }
  }
  agreePayment(isAgree: boolean) {
    this.showAgreementView = false;
    if (isAgree) {
      this.processPayment();
    }
  }
  private processPayment() {
    // This will only check if form is valid and its controls
    if (!this.validateForm()) return;
    if (this.verifySave(this.transType)) return;

    this.bSaving = true;
    this.operatePayment(this.transType);

    const ccModel: CreditCardPayments = {
      ...this.payment,
      ...this.ccInfoForm.getRawValue(),
      UserCulture: 'en-US',
    };

    // we need to encrypt the cc information
    // we can't send it in plaintext
    ccModel.CardNumber = encryptString(ccModel.CardNumber);
    ccModel.CCLast4 = encryptString(ccModel.CCLast4);

    this.orderService
      .savePayment(ccModel)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.bSaving = false;
          this.errorCode = 0;
          this.changeView();
        },
        error: (error) => {
          this.bSaving = false;
          this.processPaymentError(error);
        },
      });
    // For debug purpose only
    // setTimeout(() => {
    //   this.errorCode = 0;
    //   this.changeView();
    //   console.log(ccModel);
    // }, 2000);
  }
  setEmailAddress(email: string): void {
    if (this.ccInfoForm) {
      this.ccInfoForm.get('EmailAddress')?.setValue(email);
    }
  }
  currentPayment: number = 0.0;
  changeView(): void {
    this.initialLoad = false;
    this.order.Paid = true;
    this.currentPayment = this.preAuth.value;
  }

  changeZipCode(): void {
    this.bWrongZip = false;
    if (this.isUsAddress) this.getUsZipCode();
  }
  private processPaymentError(error: { error: string; status: number }): void {
    const errorMsg = error.error;
    if (error.status && error.status === 401) {
      this.processingError = 'Authorization has been denied for this request.';
      return;
    }
    let errorArr = errorMsg.split('||');
    let error1 = errorArr[0];
    let error2 = errorArr[1];
    this.handlePaymentError(error1, error2);
  }
  private handlePaymentError(error1: string, error2: string): void {
    if (error1 === PAYMETECH_ENDPOINT_DOWN) {
      this.validationError = true;
      this.error = error2;
    } else if (error1 === VALIDATE_DECLINE || error1 === VOID_DECLINE) {
      this.validationError = true;
      this.error = error2;
    } else if (error1 === PRE_AUTHORIZE_DECLINE) {
      this.preAuthorizeError = true;
      this.error = error2;
    } else if (error1 === CAPTURE_DECLINE) {
      this.captureError = true;
      this.error = error2;
    } else if (error1 === VERIFY_PRE_AUTH_AMOUNT) {
      this.preAuthorizeAmountVerify = true;
    } else if (error1 === PRE_AUTHORIZE_APPROVE) {
      this.alreadyPreAuthorized = true;
      this.error = error2;
    } else if (error1 === CAPTURE_APPROVE) {
      this.alreadyCaptured = true;
      this.error = error2;
    } else if (error1 === AMOUNT_MISMATCHED) {
      this.isAmountMismatched = true;
    } else if (error1 === INVALID_CARD_NUMBER) {
      this.IsCardNumberValid = false;
      this.cardNumberNameControl.setErrors({ Invalid: true });
    } else {
      this.error = 'Process failed, please refresh page and try again';
    }
    this.errorCode = 10;
  }
  validateCanadaZipcode(zipcode: string) {
    const match = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    if (!match.test(zipcode)) {
      this.zipcodeVerify = true;
    } else {
      this.zipcodeVerify = false;
    }
  }

  verifySave(transType: string): boolean {
    this.resetErrors();
    let isVeify = false;
    const useUSAddress = this.ccInfoForm.get('UseUSAddress')?.value;
    const countryID = this.ccInfoForm.get('CountryId')?.value;
    const zipCode = this.ccInfoForm.get('ZipCode')?.value;

    if (countryID == 1 && useUSAddress) {
      if (zipCode.length != 5 || this.payment.ZipCodeId == 0) {
        this.zipcodeVerify = true;
        isVeify = true;
        this.processingError = 'Postal Code is not Valid for selected country';
      }
    }

    if (
      this.payment.ProviderId === 0 &&
      this.payment.LocalOfficeCountryId === 4
    ) {
      this.isError = true;
      this.error = 'Credit card payment cannot be processed for this Country';
      isVeify = true;
    }

    const cardExpirationYear = this.ccInfoForm.get('CardExpirationYear')?.value;
    const cardExpirationMonth = this.ccInfoForm.get(
      'CardExpirationMonth'
    )?.value;
    let expiration1Date = new Date(cardExpirationYear, cardExpirationMonth, 1);
    let orderExpDate = new Date(this.order.EndDate);
    if (orderExpDate.getTime() >= expiration1Date.getTime()) {
      this.expirationError = true;
      isVeify = true;
      this.processingError =
        'The expiration date you entered for the credit card is before your order start date.';
    }
    // This most likely will not happen as i'm checking payment amoutn on bulur
    if (transType == PRE_AUTHORIZE || transType == CAPTURE) {
      const chargeRemainingBalance = this.ccInfoForm.get(
        'ChargeRemainingBalance'
      )?.value;
      if (chargeRemainingBalance) {
        if (this.remainingbalancedue <= 0 && this.payment.PaymentAmount <= 0) {
          this.CRBError = true;
          this.preAuthorizeAmountVerify = true;
          isVeify = true;
          this.processingError =
            'Please Pre-Authorize, Capture, or Void all other credit card payments before attempting to process a payment flagged to Charge Remaining Balance';
        }
      } else {
        const preAuthAmount = this.ccInfoForm.get('PreAuthAmount')?.value;
        if (preAuthAmount <= 0) {
          this.preAuthorizeAmountVerify = true;
          isVeify = true;
          this.processingError = 'The Pre-AuthAmount must be > $0';
        }
      }
    }
    this.errorCode = 9;
    return isVeify;
  }

  get isAddressCorrect(): boolean {
    return this.stateControl.value !== '' && !this.bWrongZip;
  }

  getUsZipCode(): void {
    this.bLoadingZipCodes = true;
    this.isError = false;
    this.error = '';
    this.payment.Country = null;
    this.resetForm();
    const match = /^\d{5}$/;
    const zipCode = this.ccInfoForm.get('ZipCode')?.value;
    if (match.test(zipCode)) {
      this.orderService
        .getAddressListByZipCode(zipCode)
        .pipe(
          take(1),
          finalize(() => {
            this.bLoadingZipCodes = false;
          })
        )
        .subscribe({
          next: (data) => {
            if (data) {
              if (data.length === 1) {
                this.ccInfoForm.get('State')?.setValue(data[0].State);
                this.ccInfoForm.get('CityIndex')?.setValue(0);
                this.ccInfoForm.get('State')?.setValue(data[0].State);
                this.ccInfoForm.get('City')?.setValue(data[0].City);
                this.ccInfoForm.get('CountryId')?.setValue(1);
                this.showCity = true;
                this.payment.ZipCodeId = data[0].ZipCodeID;
              }

              if (data.length > 1) {
                this.showCity = false;
                this.USAddressData = data;
                this.cityList = this.createCityList(data);
                this.payment.ZipCodeId = data[0].ZipCodeID;
              }
              if (data.length === 0) {
                this.resetForm();
                this.bWrongZip = true;
              }
            }
          },
          error: () => {
            this.bLoadingZipCodes = false;
          },
        });
    } else {
      this.bWrongZip = true;
      this.bLoadingZipCodes = false;
    }
  }
  resetForm(): void {
    this.ccInfoForm.get('State')?.setValue('');
    this.ccInfoForm.get('CityIndex')?.setValue(0);
    this.ccInfoForm.get('State')?.setValue('');
    this.ccInfoForm.get('City')?.setValue('');
    if (!this.isUsAddress) this.ccInfoForm.get('CountryId')?.setValue(-1);
    this.cityList = [];
    this.showCity = true;
  }
  createCCForm(): void {
    this.ccInfoForm = this.fb.group({
      CardHolderName: ['', Validators.required],
      CreditCardTypeId: ['', [Validators.required]],
      CardNumber: ['', Validators.required],
      CardExpirationMonth: ['', Validators.required],
      CardExpirationYear: ['', Validators.required],
      SecurityCode: [''],
      UseUSAddress: [true],
      Street: [''],
      City: [''],
      CityIndex: [''],
      State: [''],
      ZipCode: ['', [Validators.required, Validators.min(2)]],
      CountryId: ['-1'],
      EventDeposit: [false],
      PONumber: [''],
      EmailAddress: ['', [Validators.required, Validators.email]],
      ChargeRemainingBalance: [false],
      PreAuthAmount: [0],
      VoidPaymentReasonID: [0],
      SignedConsentFormOnFile: [false],
      Note: ['Submitted from PayLink'],
    });

    this.ccInfoForm.get('City')?.disable();
    this.ccInfoForm.get('State')?.disable();
    this.ccInfoForm.get('CountryId')?.disable();
  }
  get cardholderNameControl(): FormControl {
    return this.ccInfoForm.get('CardHolderName') as FormControl;
  }
  get preAuth(): FormControl {
    return this.ccInfoForm.get('PreAuthAmount') as FormControl;
  }

  get cardNumberNameControl(): FormControl {
    return this.ccInfoForm.get('CardNumber') as FormControl;
  }
  get zipControl(): FormControl {
    return this.ccInfoForm.get('ZipCode') as FormControl;
  }
  get stateControl(): FormControl {
    return this.ccInfoForm.get('State') as FormControl;
  }
  get emailControl(): FormControl {
    return this.ccInfoForm.get('EmailAddress') as FormControl;
  }
  get creditCardTypeControl(): FormControl {
    return this.ccInfoForm.get('CreditCardTypeId') as FormControl;
  }
  get isEmailMissing(): boolean {
    const ctr = this.ccInfoForm.get('EmailAddress') as FormControl;
    return ctr.value === '';
  }
  get expirationMonthControl(): FormControl {
    return this.ccInfoForm.get('CardExpirationMonth') as FormControl;
  }
  get expirationYearControl(): FormControl {
    return this.ccInfoForm.get('CardExpirationYear') as FormControl;
  }
  get isUsAddress(): boolean {
    const v = this.ccInfoForm.get('UseUSAddress') as FormControl;
    return !!v.value;
  }

  get isEventDeposit(): boolean {
    const v = this.ccInfoForm.get('EventDeposit') as FormControl;
    return v.value;
  }

  changeCountry(): void {
    if (this.isUsAddress) {
      this.ccInfoForm.get('CountryId')?.setValue(1);
      this.ccInfoForm.get('State')?.setValue('');

      this.ccInfoForm.get('City')?.disable();
      this.ccInfoForm.get('State')?.disable();
      this.ccInfoForm.get('CountryId')?.disable();
      this.payment.CountryId = 1;
    } else {
      //make the country input empty, should be set to correct value before save
      this.ccInfoForm.get('CountryId')?.setValue('-1');
      this.ccInfoForm.get('State')?.setValue('N/A');

      this.ccInfoForm.get('City')?.enable();
      this.ccInfoForm.get('State')?.enable();
      this.ccInfoForm.get('CountryId')?.enable();

      this.payment.CountryId = 480;
    }

    this.ccInfoForm.get('City')?.setValue('');
    this.ccInfoForm.get('ZipCode')?.setValue('');
    this.showCity = true;
    this.cityList = [];
    this.USAddressData = [];
  }
  setPaymentType(orderStatus: number): void {
    if (this.isEventDeposit) {
      // Event deposit is checked, capture regardless of order status
      this.transType = CAPTURE;
    } else {
      // Event deposit is NOT checked, decide based on order status
      switch (orderStatus) {
        case 1: // Quote
        case 2: // Tentative
        case 3: // Confirmed
        case 4: // Confirm - PickList
          // Validate then Pre-auth for all above order status
          this.transType = PRE_AUTHORIZE;
          break;
        case 6: // Invoiced
        case 5: // Capture for RTI (redy to invoice) only
          this.transType = CAPTURE;
          break;
        default:
          // Default action if the order status doesn't match known values
          // 7  or 8  but this should never happen as no one will send a paylink with status 7 or 8
          this.transType = VALIDATE;
      }
    }
  }

  ngOnDestroy(): void {}
  ngOnInit(): void {
    this.createCCForm();

    // const token = this.route.snapshot.paramMap.get('token')!;
    this.orderService.accessToken = this.token;
    const locationPar = getJwtLocation(this.token);
    const orderPar = getJwtOrderNumber(this.token);
    const expDate: Date = getJwtExpirationDate(this.token);

    if (!isDateExpired(expDate) && locationPar && orderPar) {
      this.getOrder(locationPar, orderPar);
      // this.getCountries(null);
    } else {
      this.bExpiredLink = true;
      this.error = `PayLink has expired on ${expDate}, contact Encore Global`;
      this.errorCode = 100;
    }
    // Subscribe to the valueChanges observable of the CardNumber control
    this.ccInfoForm
      .get('CardNumber')!
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.ccLength = value.length;
      });
  }
  getCountries(country: Params | null): void {
    this.orderService
      .getCountries(country)
      .pipe(take(1))
      .subscribe({
        next: (value) => {
          this.countries = value;
        },
        error: (err) => {
          console.error(err);
        },
      });
  }
  validateCreditCard(cardNumber: string) {
    // The Luhn Algorithm. Mod 10 check for CC
    let nCheck = 0;
    let bEven = false;
    cardNumber = cardNumber.replace(/\D/g, '');

    for (let n = cardNumber.length - 1; n >= 0; n--) {
      let cDigit = cardNumber.charAt(n);
      let nDigit = parseInt(cDigit, 10);

      if (bEven) {
        if ((nDigit *= 2) > 9) nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    this.IsCardNumberValid = nCheck % 10 == 0;
  }

  eventDepostiChange() {
    this.bWrongPaymentAmountLess = false;
    this.bWrongPaymentAmountMore = false;
    if (this.isEventDeposit) {
      this.ccInfoForm.get('PreAuthAmount')?.setValue(0);
      this.ccInfoForm.get('ChargeRemainingBalance')?.setValue(false);
    } else {
      this.ccInfoForm.get('PreAuthAmount')?.setValue(this.order.NetBalanceDue);
      this.ccInfoForm.get('ChargeRemainingBalance')?.setValue(true);
    }
    this.setPaymentType(this.order.Status);
  }
  setFormValues(): void {
    if (!this.payment) return;
    this.ccInfoForm.get('PreAuthAmount')?.setValue(this.order.NetBalanceDue);
    this.ccInfoForm.get('EventDeposit')?.setValue(false);
    this.ccInfoForm
      .get('VoidPaymentReasonID')
      ?.setValue(this.payment.VoidPaymentReasonID);
    this.ccInfoForm.get('CountryId')?.setValue(this.payment.CountryId);
    this.ccInfoForm
      .get('ChargeRemainingBalance')
      ?.setValue(this.payment.ChargeRemainingBalance);
    this.ccInfoForm.markAsPristine();
  }
}
