<div class="py-3 text-center mb-3">
  <img
    class="d-block mx-auto mb-4 encore-logo slideInDown"
    src="/assets/encore_logo.png"
    loading="lazy"
    alt="Encore Logo"
    width="192"
  />
  @if(errorCode >= 100){
  <!-- Wrong URL or Toeken has ex -->
  <p class="lead w-50 m-auto mobile fw-bold">
    Error has occurred. Please make sure you are using the correct URL that
    Encore Global provided.
  </p>
  } @if(!paid && errorCode <=10){
  <p class="lead w-50 m-auto mobile">
    Thank you for choosing Encore Global for your event!<br />
    Please find the below event details and proceed with your payment.
  </p>
  } @if(paid && errorCode === 0){
  <div class="alert alert-light w-50 m-auto mobile fw-bold mb-1" role="alert">
    Receipt
  </div>
  <p class="lead w-50 m-auto mobile">
    Thank you for choosing Encore Global for your event! Your payment has been
    processed successfully. <br />
    Please find all the important details below.
  </p>
  }
</div>
