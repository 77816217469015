import {
  ChangeDetectionStrategy,
  Component,
  OutputEmitterRef,
  output,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
@Component({
  selector: 'app-agreement-view',
  standalone: true,
  imports: [DialogModule],
  templateUrl: './agreement-view.component.html',
  styleUrl: './agreement-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgreementViewComponent {
  constructor() {}
  agreePayment: OutputEmitterRef<boolean> = output<boolean>({
    alias: 'agreePayment',
  });
  onAgree(isAgree: boolean) {
    this.agreePayment.emit(isAgree);
  }
}
