import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './Components/landing/landing.component';

const routes: Routes = [
  {
    path: 'help',
    loadComponent: () =>
      import('./Components/help-page/help-page.component').then(
        (m) => m.HelpPageComponent
      ),
  },
  {
    path: ':token',
    component: LandingComponent,
  },
  {
    path: '**',
    loadComponent: () =>
      import('./Components/page-not-found/page-not-found.component').then(
        (m) => m.PageNotFoundComponent
      ), // Lazy loading
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
